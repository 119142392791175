import React from "react"
import { BrowserRouter } from "react-router-dom"
import App from "../App"

const Browser = () => (
  <BrowserRouter>
    <App />
  </BrowserRouter>
)

export default Browser
