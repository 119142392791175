import React, { Component } from "react"
import { IntlProvider } from "react-intl"
import { RouteComponentProps, Redirect, withRouter } from "react-router-dom"
import messagesEn from "./translations/locales/en.json"
import messagesFr from "./translations/locales/fr.json"
import { UnregisterCallback } from "history"

const messages: any = {
  en: messagesEn,
  fr: messagesFr,
}

export type Locale = "en" | "fr"

export const locales: Locale[] = ["en", "fr"]
export const localesRegExp = new RegExp(`^/(${locales.join("|")})(?![a-z0-9-])`)
export const defaultLocale = "en"

export const localizePathname = function (pathname: string, locale: Locale) {
  return pathname.replace(localesRegExp, `/${locale}`)
}

export const detectLocale = function (pathname: string) {
  let locale: Locale
  if (
    typeof navigator === "undefined" ||
    (navigator.language && navigator.language.match(/^en/))
  ) {
    locale = "en"
  } else {
    locale = "fr"
  }
  let results = pathname.match(localesRegExp)
  if (results === null) {
    let redirectTo: string
    if (pathname === "/") {
      redirectTo = `/${locale}`
    } else {
      redirectTo = `/${locale}${pathname}`
    }
    return {
      locale: locale,
      redirectTo: redirectTo,
    }
  } else if (!locales.includes(results[1] as Locale)) {
    return {
      locale: locale,
      redirectTo: localizePathname(pathname, locale),
    }
  } else {
    return {
      locale: results[1] as Locale,
    }
  }
}

interface CustomIntlProviderProps extends RouteComponentProps {}

interface CustomIntlProviderState {
  locale: Locale
  messages: any
  redirectTo?: string
}

class CustomIntlProvider extends Component<
  CustomIntlProviderProps,
  CustomIntlProviderState
> {
  unregister?: UnregisterCallback
  constructor(props: CustomIntlProviderProps) {
    super(props)
    let { locale, redirectTo } = detectLocale(this.props.location.pathname)
    this.state = {
      locale: locale,
      messages: messages[locale],
      redirectTo: redirectTo,
    }
  }
  componentDidMount() {
    this.setState({
      redirectTo: undefined,
    })
    this.unregister = this.props.history.listen((location) => {
      let { locale, redirectTo } = detectLocale(location.pathname)
      this.setState({
        locale: locale,
        messages: messages[locale],
        redirectTo: redirectTo,
      })
    })
  }
  componentWillUnmount() {
    if (this.unregister) {
      this.unregister()
    }
  }
  render() {
    if (this.state.redirectTo) {
      return <Redirect to={this.state.redirectTo} />
    } else {
      return (
        <IntlProvider
          locale={this.state.locale}
          defaultLocale={defaultLocale}
          messages={this.state.messages}
        >
          {this.props.children}
        </IntlProvider>
      )
    }
  }
}

export default withRouter(CustomIntlProvider)
