import React, { FunctionComponent, Component } from "react"
import { RouteComponentProps } from "react-router-dom"
import {
  injectIntl,
  defineMessages,
  FormattedMessage,
  WrappedComponentProps as IntlComponentProps,
} from "react-intl"
import { Theme, withTheme } from "@material-ui/core/styles"
import { Link as RouterLink } from "react-router-dom"
import { Typography, Button, TextField, Link } from "@material-ui/core"
import { Formik, FormikHelpers } from "formik"
import * as yup from "yup"
import styled from "styled-components"
import request from "superagent"
import { Redirect } from "react-router"
import { setAccessToken } from "../CustomAuthProvider"
import Box from "../Box"
import StatusSnackbar from "../StatusSnackbar"

const messages = defineMessages({
  emailLabel: {
    id: "login.emailLabel",
    defaultMessage: "Email",
  },
  emailEnterValid: {
    id: "login.emailEnterValid",
    defaultMessage: "Enter valid email",
  },
  emailRequired: {
    id: "login.emailRequired",
    defaultMessage: "Email required",
  },
  passwordLabel: {
    id: "login.passwordLabel",
    defaultMessage: "Password",
  },
  passwordMinLength: {
    id: "login.passwordMinLength",
    defaultMessage: "Password must contain at least 6 characters",
  },
  passwordRequired: {
    id: "login.passwordRequired",
    defaultMessage: "Password required",
  },
  wrongCredentials: {
    id: "login.wrongCredentials",
    defaultMessage: "Wrong credentials",
  },
  guestAccount: {
    id: "login.guestAccount",
    defaultMessage: "Please use guest login",
  },
  couldNotLogIn: {
    id: "login.couldNotLogIn",
    defaultMessage: "Could not log in",
  },
})

interface ActionsProps {
  theme: Theme
}

const Actions = withTheme(
  styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media only screen and (max-width: ${(props: ActionsProps) =>
        props.theme.breakpoints.values.sm}px) {
      flex-direction: column;
      align-items: flex-end;
    }
  `
)

const Left = withTheme(
  styled.div`
    @media only screen and (max-width: ${(props: ActionsProps) =>
        props.theme.breakpoints.values.sm}px) {
      margin-bottom: 22.5px;
    }
  `
)

const Right = styled.div``

interface LoginFormValues {
  email: string
  password: string
}

interface LoginFormProps extends IntlComponentProps {
  onSubmit: (
    values: LoginFormValues,
    actions: FormikHelpers<LoginFormValues>
  ) => void
}

export const LoginForm: FunctionComponent<LoginFormProps> = (props) => {
  const initialValues: LoginFormValues = { email: "", password: "" }
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={() => {
        return yup.object().shape({
          email: yup
            .string()
            .email(props.intl.formatMessage(messages.emailEnterValid))
            .required(props.intl.formatMessage(messages.emailRequired)),
          password: yup
            .string()
            .min(6, props.intl.formatMessage(messages.passwordMinLength))
            .required(props.intl.formatMessage(messages.passwordRequired)),
        })
      }}
      onSubmit={props.onSubmit}
    >
      {(formikProps) => {
        return (
          <form onSubmit={formikProps.handleSubmit}>
            <TextField
              autoFocus
              fullWidth
              type="text"
              name="email"
              label={props.intl.formatMessage(messages.emailLabel)}
              onChange={formikProps.handleChange}
              // onBlur={formikProps.handleBlur}
              helperText={
                formikProps.touched.email ? formikProps.errors.email : ""
              }
              error={
                formikProps.touched.email && Boolean(formikProps.errors.email)
              }
              value={formikProps.values.email}
            />
            <br />
            <br />
            <TextField
              fullWidth
              type="password"
              name="password"
              label={props.intl.formatMessage(messages.passwordLabel)}
              onChange={formikProps.handleChange}
              // onBlur={formikProps.handleBlur}
              helperText={
                formikProps.touched.password ? formikProps.errors.password : ""
              }
              error={
                formikProps.touched.password &&
                Boolean(formikProps.errors.password)
              }
              value={formikProps.values.password}
            />
            <br />
            <br />
            <br />
            <Actions>
              <Left>
                <Link component={RouterLink} to={`/${props.intl.locale}/reset`}>
                  <FormattedMessage
                    id="login.lostPassword"
                    defaultMessage="Lost password?"
                  />
                </Link>
              </Left>
              <Right>
                <Button
                  color="primary"
                  variant="contained"
                  size="large"
                  type="submit"
                  disabled={formikProps.isSubmitting}
                >
                  <FormattedMessage id="login.logIn" defaultMessage="Log in" />
                </Button>
              </Right>
            </Actions>
          </form>
        )
      }}
    </Formik>
  )
}

interface LoginProps extends RouteComponentProps, IntlComponentProps {}

interface LoginState {
  error?: string
  showError: boolean
  authenticated: boolean
}

class Login extends Component<LoginProps, LoginState> {
  constructor(props: LoginProps) {
    super(props)
    this.state = {
      showError: false,
      authenticated: false,
    }
  }
  render() {
    if (this.state.authenticated) {
      return <Redirect to={`/${this.props.intl.locale}/send`} />
    } else {
      return (
        <Box>
          <Typography variant="h2">
            <FormattedMessage
              id="login.enterEmailAndPassword"
              defaultMessage="Please enter your email and password"
            />
          </Typography>
          <br />
          <LoginForm
            intl={this.props.intl}
            onSubmit={(values, actions) => {
              const req = request.post(
                `${process.env.REACT_APP_POLYINC_API_PREFIX_URL}/v1/login`
              )
              req.send(values)
              req.end((error, response) => {
                actions.setSubmitting(false)
                if (error || response.status !== 200) {
                  let error: string
                  if (response?.status === 401) {
                    error = this.props.intl.formatMessage(
                      messages.wrongCredentials
                    )
                  } else if (response?.status === 403) {
                    error = this.props.intl.formatMessage(messages.guestAccount)
                  } else {
                    error = this.props.intl.formatMessage(
                      messages.couldNotLogIn
                    )
                  }
                  this.setState({
                    error: error,
                    showError: true,
                  })
                } else {
                  actions.resetForm()
                  setAccessToken(response.body.accessToken)
                  this.setState({
                    error: undefined,
                    showError: false,
                    authenticated: true,
                  })
                }
              })
            }}
          />
          <StatusSnackbar
            open={this.state.showError}
            handleClose={() => {
              this.setState({
                showError: false,
              })
            }}
            message={this.state.error}
          />
        </Box>
      )
    }
  }
}

export default injectIntl(Login)
