import React from "react"
import {
  injectIntl,
  FormattedMessage,
  WrappedComponentProps as IntlComponentProps,
} from "react-intl"
import { RouteComponentProps, withRouter } from "react-router-dom"
import { Typography, Button } from "@material-ui/core"
import { Link as RouterLink, Redirect } from "react-router-dom"
import { Link } from "@material-ui/core"
import { getAccessToken } from "../CustomAuthProvider"
import Box from "../Box"

interface HomeProps extends IntlComponentProps, RouteComponentProps {}

const Home = function (props: HomeProps) {
  let accessToken = getAccessToken(props.staticContext)
  if (accessToken) {
    return <Redirect to={`/${props.intl.locale}/send`} />
  } else {
    return (
      <Box>
        <Typography variant="h2">
          <FormattedMessage
            id="home.welcome"
            defaultMessage="Welcome to Les Industries Poly Inc."
          />
        </Typography>
        <br />
        <Typography variant="body1" style={{ display: "inline-block" }}>
          <FormattedMessage
            id="home.needAccount"
            defaultMessage="Need an account?"
          />
        </Typography>{" "}
        <Link
          component={RouterLink}
          to={`/${props.intl.locale}/requestaccount`}
        >
          <Button color="secondary" variant="contained" size="small">
            <FormattedMessage
              id="home.requestAccount"
              defaultMessage="Request account"
            />
          </Button>
        </Link>
        <br />
        <br />
        <br />
        <Link component={RouterLink} to={`/${props.intl.locale}/login`}>
          <Button color="primary" variant="contained" size="large" fullWidth>
            <FormattedMessage
              id="home.login"
              defaultMessage="Account login..."
            />
          </Button>
        </Link>
        <br />
        <br />
        <Link component={RouterLink} to={`/${props.intl.locale}/login/guest`}>
          <Button color="primary" size="large" fullWidth>
            <FormattedMessage
              id="home.guestLogin"
              defaultMessage="Guest login..."
            />
          </Button>
        </Link>
      </Box>
    )
  }
}

export default injectIntl(withRouter(Home))
