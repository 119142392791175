import React, { FunctionComponent, Component, Fragment } from "react"
import { RouteComponentProps } from "react-router-dom"
import {
  injectIntl,
  defineMessages,
  FormattedMessage,
  WrappedComponentProps as IntlComponentProps,
} from "react-intl"
import {
  Button,
  Link,
  TextField,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  MenuItem,
  Typography,
  Accordion,
  AccordionSummary,
} from "@material-ui/core"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import { Formik, FormikHelpers } from "formik"
import * as yup from "yup"
import styled from "styled-components"
import { transitions, darken } from "polished"
import prettyBytes from "pretty-bytes"
import request from "superagent"
import CustomAuthProvider, {
  ShippingAddress,
  CustomAuthConsumer,
} from "../CustomAuthProvider"
import Box from "../Box"
import StatusSnackbar from "../StatusSnackbar"
import { Locale } from "../CustomIntlProvider"
import Drop, {
  File,
  DropContainerProps,
  DropLoadingProps,
  DropContextProvider,
  DropContextConsumer,
  DropContextProps,
  DropRejectionError,
  DropInProgressError,
  DropUploadError,
} from "../Drop"

const messages = defineMessages({
  invalidOrDuplicateFile: {
    id: "send.invalidOrDuplicateFile",
    defaultMessage: "Invalid or duplicate file",
  },
  uploadInProgress: {
    id: "send.uploadInProgress",
    defaultMessage: "Upload in progress",
  },
  couldNotUploadFiles: {
    id: "send.couldNotUploadFiles",
    defaultMessage: "Could not upload files",
  },
  referenceIdLabel: {
    id: "send.referenceIdLabel",
    defaultMessage: "Reference ID",
  },
  referenceIdRequired: {
    id: "send.referenceIdRequired",
    defaultMessage: "Reference ID required",
  },
  instructionsLabel: {
    id: "send.instructionsLabel",
    defaultMessage: "Instructions",
  },
  instructionsRequired: {
    id: "send.instructionsRequired",
    defaultMessage: "Instructions required",
  },
  shippingAddressRequired: {
    id: "send.shippingAddressRequired",
    defaultMessage: "Shipping address required",
  },
  coversQuantityLabel: {
    id: "send.coversQuantityLabel",
    defaultMessage: "Quantity",
  },
  separatorsQuantityLabel: {
    id: "send.separatorsQuantityLabel",
    defaultMessage: "Quantity",
  },
  uploadFile: {
    id: "send.uploadFile",
    defaultMessage: "Please upload at least one file",
  },
  couldNotSend: {
    id: "send.couldNotSend",
    defaultMessage: "Could not send files",
  },
})

interface LogoutFormValues {}

interface LogoutFormProps extends IntlComponentProps {
  onSubmit: (
    values: LogoutFormValues,
    actions: FormikHelpers<LogoutFormValues>
  ) => void
}

export const LogoutForm: FunctionComponent<LogoutFormProps> = (props) => {
  const initialValues: LogoutFormValues = {}
  return (
    <Formik initialValues={initialValues} onSubmit={props.onSubmit}>
      {(formikProps) => {
        return (
          <form
            style={{ display: "inline" }}
            onSubmit={formikProps.handleSubmit}
          >
            <Button
              color="secondary"
              variant="contained"
              size="small"
              type="submit"
              disabled={formikProps.isSubmitting}
            >
              <FormattedMessage id="send.logout" defaultMessage="Log out" />
            </Button>
          </form>
        )
      }}
    </Formik>
  )
}

const DropContainer = styled.div<DropContainerProps>`
  overflow: auto;
  height: 100vh;
  ${transitions("background-color 0.3s")}
  ${({ isDragAccept }) => {
    if (isDragAccept) {
      return `
        background-color: ${darken(0.05, "white")};
      `
    }
  }}
`

const DropLoading = styled.div<DropLoadingProps>`
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  width: ${({ percent }) => percent}%;
  height: 100%;
  background-color: ${darken(0.05, "white")};
  opacity: 0;
  z-index: -1;
  ${transitions(["opacity", "width"], "0.3s")}
  ${({ uploading }) => {
    if (uploading) {
      return "opacity: 1;"
    }
  }}
`

interface UploadedFileProps {
  locale: Locale
  dropContext: DropContextProps
  file: File
}

const UploadedFile = function (props: UploadedFileProps) {
  return (
    <div>
      {props.file.originalname} (
      {prettyBytes(props.file.size, { locale: props.locale })}){" "}
      <Link
        href=""
        onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
          event.preventDefault()
          if (props.dropContext.removeFile) {
            props.dropContext.removeFile(props.file.originalname)
          }
        }}
      >
        <FormattedMessage id="send.remove" defaultMessage="Remove" />
      </Link>
    </div>
  )
}

const Actions = styled.div`
  text-align: right;
`

interface SendFormValues {
  referenceId: string
  instructions: string
  shippingAddress: string
  coversFormat: string
  coversQuantity: string
  coversBleed: string
  coversLamination: string
  separatorsFormat: string
  separatorsTemplate: string
  separatorsQuantity: string
  locale: string
}

interface SendFormProps extends IntlComponentProps {
  shippingAddresses?: ShippingAddress[]
  onSubmit: (
    values: SendFormValues,
    actions: FormikHelpers<SendFormValues>
  ) => void
}

export const SendForm: FunctionComponent<SendFormProps> = (props) => {
  const initialValues: SendFormValues = {
    referenceId: "",
    instructions: "",
    shippingAddress:
      !props.shippingAddresses || props.shippingAddresses.length === 0
        ? "default"
        : "",
    coversFormat: "",
    coversQuantity: "",
    coversBleed: "",
    coversLamination: "",
    separatorsFormat: "",
    separatorsTemplate: "",
    separatorsQuantity: "",
    locale: props.intl.locale,
  }
  let shippingAddresses: React.ReactNode[] = []
  if (props.shippingAddresses) {
    for (const address of props.shippingAddresses) {
      shippingAddresses.push(
        <MenuItem key={address.id} value={address.id}>
          {address.name}
        </MenuItem>
      )
    }
  }
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={() => {
        return yup.object().shape({
          referenceId: yup
            .string()
            .required(props.intl.formatMessage(messages.referenceIdRequired)),
          instructions: yup
            .string()
            .required(props.intl.formatMessage(messages.instructionsRequired)),
          shippingAddress: yup
            .string()
            .required(
              props.intl.formatMessage(messages.shippingAddressRequired)
            ),
        })
      }}
      onSubmit={props.onSubmit}
    >
      {(formikProps) => {
        let shipping: React.ReactNode
        if (props.shippingAddresses && props.shippingAddresses.length > 0) {
          let shippingAddressHelperText: React.ReactNode | undefined = undefined
          if (
            formikProps.touched.shippingAddress &&
            Boolean(formikProps.errors.shippingAddress)
          ) {
            shippingAddressHelperText = (
              <FormHelperText>
                {formikProps.errors.shippingAddress}
              </FormHelperText>
            )
          }
          shipping = (
            <Fragment>
              <br />
              <br />
              <FormControl
                error={
                  formikProps.touched.shippingAddress &&
                  Boolean(formikProps.errors.shippingAddress)
                }
                fullWidth
              >
                <InputLabel id="shipping-address-label">
                  <FormattedMessage
                    id="send.shippingAddress"
                    defaultMessage="Shipping address"
                  />
                </InputLabel>
                <Select
                  name="shippingAddress"
                  onChange={formikProps.handleChange}
                  value={formikProps.values.shippingAddress}
                  labelId="shipping-address-label"
                >
                  <MenuItem value="default">
                    <FormattedMessage
                      id="send.defaultShippingAddress"
                      defaultMessage="Default shipping address"
                    />
                  </MenuItem>
                  {shippingAddresses}
                </Select>
                {shippingAddressHelperText}
              </FormControl>
            </Fragment>
          )
        } else {
          shipping = (
            <TextField
              type="hidden"
              name="shippingAddress"
              value={formikProps.values.shippingAddress}
            />
          )
        }
        return (
          <form onSubmit={formikProps.handleSubmit}>
            <TextField
              fullWidth
              type="text"
              name="referenceId"
              label={props.intl.formatMessage(messages.referenceIdLabel)}
              onChange={formikProps.handleChange}
              // onBlur={formikProps.handleBlur}
              helperText={
                formikProps.touched.referenceId
                  ? formikProps.errors.referenceId
                  : ""
              }
              error={
                formikProps.touched.referenceId &&
                Boolean(formikProps.errors.referenceId)
              }
              value={formikProps.values.referenceId}
            />
            <br />
            <br />
            <TextField
              fullWidth
              multiline
              type="text"
              name="instructions"
              label={props.intl.formatMessage(messages.instructionsLabel)}
              onChange={formikProps.handleChange}
              // onBlur={formikProps.handleBlur}
              helperText={
                formikProps.touched.instructions
                  ? formikProps.errors.instructions
                  : ""
              }
              error={
                formikProps.touched.instructions &&
                Boolean(formikProps.errors.instructions)
              }
              value={formikProps.values.instructions}
            />
            {shipping}
            <br />
            <br />
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="advanced-options-content"
                id="advanced-options-header"
              >
                <strong>
                  <FormattedMessage
                    id="send.advancedOptions"
                    defaultMessage="Advanced options"
                  />
                </strong>
              </AccordionSummary>
              <br />
              <br />
              <strong>
                <FormattedMessage id="send.covers" defaultMessage="Covers" />
              </strong>
              <br />
              <FormControl fullWidth>
                <InputLabel id="covers-format-label">
                  <FormattedMessage
                    id="send.coversFormat"
                    defaultMessage="Format"
                  />
                </InputLabel>
                <Select
                  name="coversFormat"
                  onChange={formikProps.handleChange}
                  value={formikProps.values.coversFormat}
                  labelId="covers-format-label"
                >
                  <MenuItem value="">
                    <FormattedMessage
                      id="send.notSet"
                      defaultMessage="Not set"
                    />
                  </MenuItem>
                  <MenuItem value="8.5 x 11">8.5 x 11</MenuItem>
                  <MenuItem value="8.5 x 14">8.5 x 14</MenuItem>
                  <MenuItem value="9 x 11">9 x 11</MenuItem>
                </Select>
              </FormControl>
              <br />
              <br />
              <TextField
                fullWidth
                type="text"
                name="coversQuantity"
                label={props.intl.formatMessage(messages.coversQuantityLabel)}
                onChange={formikProps.handleChange}
                // onBlur={formikProps.handleBlur}
                helperText={
                  formikProps.touched.coversQuantity
                    ? formikProps.errors.coversQuantity
                    : ""
                }
                error={
                  formikProps.touched.coversQuantity &&
                  Boolean(formikProps.errors.coversQuantity)
                }
                value={formikProps.values.coversQuantity}
              />
              <br />
              <br />
              <FormControl fullWidth>
                <InputLabel id="covers-bleed-label">
                  <FormattedMessage
                    id="send.coversBleed"
                    defaultMessage="Bleed"
                  />
                </InputLabel>
                <Select
                  name="coversBleed"
                  onChange={formikProps.handleChange}
                  value={formikProps.values.coversBleed}
                  labelId="covers-bleed-label"
                >
                  <MenuItem value="">
                    <FormattedMessage
                      id="send.notSet"
                      defaultMessage="Not set"
                    />
                  </MenuItem>
                  <MenuItem value="Yes">
                    <FormattedMessage
                      id="send.coversBleedYes"
                      defaultMessage="Yes"
                    />
                  </MenuItem>
                  <MenuItem value="No">
                    <FormattedMessage
                      id="send.coversBleedNo"
                      defaultMessage="No"
                    />
                  </MenuItem>
                </Select>
              </FormControl>
              <br />
              <br />
              <FormControl fullWidth>
                <InputLabel id="covers-lamination-label">
                  <FormattedMessage
                    id="send.coversLamination"
                    defaultMessage="Lamination"
                  />
                </InputLabel>
                <Select
                  name="coversLamination"
                  onChange={formikProps.handleChange}
                  value={formikProps.values.coversLamination}
                  labelId="covers-lamination-label"
                >
                  <MenuItem value="">
                    <FormattedMessage
                      id="send.notSet"
                      defaultMessage="Not set"
                    />
                  </MenuItem>
                  <MenuItem value="Glossy">
                    <FormattedMessage
                      id="send.coversLaminationGlossy"
                      defaultMessage="Glossy"
                    />
                  </MenuItem>
                  <MenuItem value="Mat">
                    <FormattedMessage
                      id="send.coversLaminationMat"
                      defaultMessage="Mat"
                    />
                  </MenuItem>
                  <MenuItem value="None">
                    <FormattedMessage
                      id="send.coversLaminationNone"
                      defaultMessage="None"
                    />
                  </MenuItem>
                </Select>
              </FormControl>
              <br />
              <br />
              <br />
              <strong>
                <FormattedMessage
                  id="send.separators"
                  defaultMessage="Separators"
                />
              </strong>
              <br />
              <FormControl fullWidth>
                <InputLabel id="separators-format-label">
                  <FormattedMessage
                    id="send.separatorsFormat"
                    defaultMessage="Format"
                  />
                </InputLabel>
                <Select
                  name="separatorsFormat"
                  onChange={formikProps.handleChange}
                  value={formikProps.values.separatorsFormat}
                  labelId="separators-format-label"
                >
                  <MenuItem value="">
                    <FormattedMessage
                      id="send.notSet"
                      defaultMessage="Not set"
                    />
                  </MenuItem>
                  <MenuItem value="8.5 x 11">8.5 x 11</MenuItem>
                  <MenuItem value="8.5 x 14">8.5 x 14</MenuItem>
                  <MenuItem value="9 x 11">9 x 11</MenuItem>
                </Select>
              </FormControl>
              <br />
              <br />
              <FormControl fullWidth>
                <InputLabel id="separators-template-label">
                  <FormattedMessage
                    id="send.separatorsTemplate"
                    defaultMessage="Template"
                  />
                </InputLabel>
                <Select
                  name="separatorsTemplate"
                  onChange={formikProps.handleChange}
                  value={formikProps.values.separatorsTemplate}
                  labelId="separators-template-label"
                >
                  <MenuItem value="">
                    <FormattedMessage
                      id="send.notSet"
                      defaultMessage="Not set"
                    />
                  </MenuItem>
                  <MenuItem value="1-5">1-5</MenuItem>
                  <MenuItem value="1-8">1-8</MenuItem>
                  <MenuItem value="1-10">1-10</MenuItem>
                </Select>
              </FormControl>
              <br />
              <br />
              <TextField
                fullWidth
                type="text"
                name="separatorsQuantity"
                label={props.intl.formatMessage(
                  messages.separatorsQuantityLabel
                )}
                onChange={formikProps.handleChange}
                // onBlur={formikProps.handleBlur}
                helperText={
                  formikProps.touched.separatorsQuantity
                    ? formikProps.errors.separatorsQuantity
                    : ""
                }
                error={
                  formikProps.touched.separatorsQuantity &&
                  Boolean(formikProps.errors.separatorsQuantity)
                }
                value={formikProps.values.separatorsQuantity}
              />
            </Accordion>
            <br />
            <br />
            <br />
            <Actions>
              <Button
                color="primary"
                variant="contained"
                size="large"
                type="submit"
                disabled={formikProps.isSubmitting}
              >
                <FormattedMessage id="send.send" defaultMessage="Send" />
              </Button>
            </Actions>
          </form>
        )
      }}
    </Formik>
  )
}

interface SendProps extends RouteComponentProps, IntlComponentProps {}

interface SendState {
  uploading: boolean
  error?: string
  showError: boolean
  sent: boolean
}

class Send extends Component<SendProps, SendState> {
  constructor(props: SendProps) {
    super(props)
    this.state = {
      uploading: false,
      showError: false,
      sent: false,
    }
  }
  render() {
    if (this.state.sent) {
      return (
        <Box>
          <Typography variant="h2">
            <FormattedMessage
              id="send.confirmation"
              defaultMessage="Thanks! We will be in touch shortly!"
            />
          </Typography>
          <br />
          <br />
          <Actions>
            <Button
              color="primary"
              variant="contained"
              size="large"
              type="submit"
              onClick={() => {
                this.setState({ sent: false })
              }}
            >
              <FormattedMessage
                id="send.sendOtherFiles"
                defaultMessage="Send other files"
              />
            </Button>
          </Actions>
        </Box>
      )
    } else {
      return (
        <CustomAuthProvider>
          <CustomAuthConsumer>
            {(customAuthContext) => {
              return (
                <DropContextProvider>
                  <Drop
                    maxSize={1000000000}
                    noClick
                    container={DropContainer}
                    loading={DropLoading}
                    endpoint={`${process.env.REACT_APP_POLYINC_API_PREFIX_URL}/v1/upload`}
                    authorization={`Bearer ${customAuthContext.accessToken}`}
                    onUploadInitiated={() => {
                      this.setState({
                        uploading: true,
                      })
                    }}
                    onUploadCompleted={(files) => {
                      this.setState({
                        uploading: false,
                      })
                    }}
                    onError={(error) => {
                      if (error instanceof DropRejectionError) {
                        this.setState({
                          error: this.props.intl.formatMessage(
                            messages.invalidOrDuplicateFile
                          ),
                          showError: true,
                        })
                      } else if (error instanceof DropInProgressError) {
                        this.setState({
                          error: this.props.intl.formatMessage(
                            messages.uploadInProgress
                          ),
                          showError: true,
                        })
                      } else if (error instanceof DropUploadError) {
                        this.setState({
                          error: this.props.intl.formatMessage(
                            messages.couldNotUploadFiles
                          ),
                          showError: true,
                        })
                      }
                    }}
                  >
                    <Box>
                      <FormattedMessage
                        id="send.hello"
                        defaultMessage="Hello"
                      />{" "}
                      {customAuthContext.contact?.firstName}{" "}
                      <LogoutForm
                        intl={this.props.intl}
                        onSubmit={async (values, actions) => {
                          if (customAuthContext.logout) {
                            let ok = await customAuthContext.logout()
                            if (!ok) {
                              actions.setSubmitting(false)
                            }
                          }
                        }}
                      />
                      <br />
                      <br />
                      <DropContextConsumer>
                        {(dropContext) => {
                          let files: React.ReactNode[] = []
                          if (dropContext.files.length > 0) {
                            for (const file of dropContext.files) {
                              files.push(
                                <UploadedFile
                                  key={file.originalname}
                                  locale={this.props.intl.locale as Locale}
                                  dropContext={dropContext}
                                  file={file}
                                />
                              )
                            }
                          }
                          return (
                            <Fragment>
                              <Typography variant="h3">
                                <FormattedMessage
                                  id="send.step1"
                                  defaultMessage="Step 1. Drag and drop files on this window or use the following button to select files."
                                />
                              </Typography>
                              <br />
                              <Button
                                color="primary"
                                variant="contained"
                                size="small"
                                type="submit"
                                onClick={() => {
                                  if (dropContext.open) {
                                    dropContext.open()
                                  }
                                }}
                              >
                                <FormattedMessage
                                  id="send.selectFiles"
                                  defaultMessage="Select files"
                                />
                              </Button>
                              {files}
                              <br />
                              <br />
                              <Typography variant="h3">
                                <FormattedMessage
                                  id="send.step2"
                                  defaultMessage="Step 2. Fill out the following form and hit send."
                                />
                              </Typography>
                              <br />
                              <FormattedMessage
                                id="send.aboutReferenceId"
                                defaultMessage="The following reference ID is required and can be set to anything you want."
                              />
                              <br />
                              <SendForm
                                intl={this.props.intl}
                                shippingAddresses={
                                  customAuthContext.contact?.shippingAddresses
                                }
                                onSubmit={(values, actions) => {
                                  if (dropContext.files.length === 0) {
                                    actions.setSubmitting(false)
                                    this.setState({
                                      error: this.props.intl.formatMessage(
                                        messages.uploadFile
                                      ),
                                      showError: true,
                                    })
                                    return
                                  }
                                  const req = request.post(
                                    `${process.env.REACT_APP_POLYINC_API_PREFIX_URL}/v1/send`
                                  )
                                  req.set(
                                    "authorization",
                                    `Bearer ${customAuthContext.accessToken}`
                                  )
                                  req.send(
                                    Object.assign(values, {
                                      files: dropContext.files,
                                    })
                                  )
                                  req.end((error, response) => {
                                    actions.setSubmitting(false)
                                    if (error || response.status !== 200) {
                                      this.setState({
                                        error: this.props.intl.formatMessage(
                                          messages.couldNotSend
                                        ),
                                        showError: true,
                                      })
                                    } else {
                                      actions.resetForm()
                                      if (dropContext.reset) {
                                        dropContext.reset()
                                      }
                                      this.setState({
                                        error: undefined,
                                        showError: false,
                                        sent: true,
                                      })
                                    }
                                  })
                                }}
                              />
                            </Fragment>
                          )
                        }}
                      </DropContextConsumer>
                      <StatusSnackbar
                        open={this.state.showError}
                        handleClose={() => {
                          this.setState({
                            showError: false,
                          })
                        }}
                        message={this.state.error}
                      />
                    </Box>
                  </Drop>
                </DropContextProvider>
              )
            }}
          </CustomAuthConsumer>
        </CustomAuthProvider>
      )
    }
  }
}

export default injectIntl(Send)
