import React, { FunctionComponent, Component } from "react"
import { RouteComponentProps } from "react-router-dom"
import {
  injectIntl,
  defineMessages,
  FormattedMessage,
  WrappedComponentProps as IntlComponentProps,
} from "react-intl"
import { Typography, Button, TextField, Link } from "@material-ui/core"
import { Formik, FormikHelpers } from "formik"
import * as yup from "yup"
import styled from "styled-components"
import request from "superagent"
import Box from "../Box"
import StatusSnackbar from "../StatusSnackbar"

const messages = defineMessages({
  accountNameLabel: {
    id: "requestAccount.accountNameLabel",
    defaultMessage: "Name",
  },
  accountNameRequired: {
    id: "requestAccount.accountNameRequired",
    defaultMessage: "Name required",
  },
  accountPhoneLabel: {
    id: "requestAccount.accountPhoneLabel",
    defaultMessage: "Phone",
  },
  accountPhoneMinLength: {
    id: "requestAccount.accountPhoneMinLength",
    defaultMessage: "Phone must contain at least 10 characters",
  },
  accountPhoneRequired: {
    id: "requestAccount.accountPhoneRequired",
    defaultMessage: "Phone required",
  },
  accountBillingStreetLabel: {
    id: "requestAccount.accountBillingStreetLabel",
    defaultMessage: "Street",
  },
  accountBillingStreetRequired: {
    id: "requestAccount.accountBillingStreetRequired",
    defaultMessage: "Street required",
  },
  accountBillingCityLabel: {
    id: "requestAccount.accountBillingCityLabel",
    defaultMessage: "City",
  },
  accountBillingCityRequired: {
    id: "requestAccount.accountBillingCityRequired",
    defaultMessage: "City required",
  },
  accountBillingStateLabel: {
    id: "requestAccount.accountBillingStateLabel",
    defaultMessage: "Province",
  },
  accountBillingStateRequired: {
    id: "requestAccount.accountBillingStateRequired",
    defaultMessage: "Province required",
  },
  accountBillingCodeLabel: {
    id: "requestAccount.accountBillingCodeLabel",
    defaultMessage: "Postal code",
  },
  accountBillingCodeRequired: {
    id: "requestAccount.accountBillingCodeRequired",
    defaultMessage: "Postal code required",
  },
  accountBillingCountryLabel: {
    id: "requestAccount.accountBillingCountryLabel",
    defaultMessage: "Country",
  },
  accountBillingCountryRequired: {
    id: "requestAccount.accountBillingCountryRequired",
    defaultMessage: "Country required",
  },
  accountShippingStreetLabel: {
    id: "requestAccount.accountShippingStreetLabel",
    defaultMessage: "Street",
  },
  accountShippingStreetRequired: {
    id: "requestAccount.accountShippingStreetRequired",
    defaultMessage: "Street required",
  },
  accountShippingCityLabel: {
    id: "requestAccount.accountShippingCityLabel",
    defaultMessage: "City",
  },
  accountShippingCityRequired: {
    id: "requestAccount.accountShippingCityRequired",
    defaultMessage: "City required",
  },
  accountShippingStateLabel: {
    id: "requestAccount.accountShippingStateLabel",
    defaultMessage: "Province",
  },
  accountShippingStateRequired: {
    id: "requestAccount.accountShippingStateRequired",
    defaultMessage: "Province required",
  },
  accountShippingCodeLabel: {
    id: "requestAccount.accountShippingCodeLabel",
    defaultMessage: "Postal code",
  },
  accountShippingCodeRequired: {
    id: "requestAccount.accountShippingCodeRequired",
    defaultMessage: "Postal code required",
  },
  accountShippingCountryLabel: {
    id: "requestAccount.accountShippingCountryLabel",
    defaultMessage: "Country",
  },
  accountShippingCountryRequired: {
    id: "requestAccount.accountShippingCountryRequired",
    defaultMessage: "Country required",
  },
  contactFirstNameLabel: {
    id: "requestAccount.contactFirstNameLabel",
    defaultMessage: "First name",
  },
  contactFirstNameRequired: {
    id: "requestAccount.contactFirstNameRequired",
    defaultMessage: "First name required",
  },
  contactLastNameLabel: {
    id: "requestAccount.contactLastNameLabel",
    defaultMessage: "Last name",
  },
  contactLastNameRequired: {
    id: "requestAccount.contactLastNameRequired",
    defaultMessage: "Last name required",
  },
  contactTitleLabel: {
    id: "requestAccount.contactTitleLabel",
    defaultMessage: "Title",
  },
  contactTitleRequired: {
    id: "requestAccount.contactTitleRequired",
    defaultMessage: "Title required",
  },
  contactEmailLabel: {
    id: "requestAccount.contactEmailLabel",
    defaultMessage: "Email",
  },
  contactEmailEnterValid: {
    id: "requestAccount.contactEmailEnterValid",
    defaultMessage: "Enter valid email",
  },
  contactEmailRequired: {
    id: "requestAccount.contactEmailRequired",
    defaultMessage: "Email required",
  },
  contactPhoneLabel: {
    id: "requestAccount.contactPhoneLabel",
    defaultMessage: "Phone",
  },
  contactPhoneMinLength: {
    id: "requestAccount.contactPhoneMinLength",
    defaultMessage: "Phone must contain at least 10 characters",
  },
  contactPhoneRequired: {
    id: "requestAccount.contactPhoneRequired",
    defaultMessage: "Phone required",
  },
  couldNotRequestAccount: {
    id: "requestAccount.couldNotRequestAccount",
    defaultMessage: "Could not request account",
  },
})

const Actions = styled.div`
  text-align: right;
`

interface RequestAccountFormValues {
  accountName: string
  accountPhone: string
  accountBillingStreet: string
  accountBillingCity: string
  accountBillingState: string
  accountBillingCode: string
  accountBillingCountry: string
  accountShippingStreet: string
  accountShippingCity: string
  accountShippingState: string
  accountShippingCode: string
  accountShippingCountry: string
  contactFirstName: string
  contactLastName: string
  contactTitle: string
  contactEmail: string
  contactPhone: string
  locale: string
}

interface RequestAccountFormProps extends IntlComponentProps {
  onSubmit: (
    values: RequestAccountFormValues,
    actions: FormikHelpers<RequestAccountFormValues>
  ) => void
}

export const RequestAccountForm: FunctionComponent<RequestAccountFormProps> = (
  props
) => {
  const initialValues: RequestAccountFormValues = {
    accountName: "",
    accountPhone: "",
    accountBillingStreet: "",
    accountBillingCity: "",
    accountBillingState: "",
    accountBillingCode: "",
    accountBillingCountry: "",
    accountShippingStreet: "",
    accountShippingCity: "",
    accountShippingState: "",
    accountShippingCode: "",
    accountShippingCountry: "",
    contactFirstName: "",
    contactLastName: "",
    contactTitle: "",
    contactEmail: "",
    contactPhone: "",
    locale: props.intl.locale,
  }
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={() => {
        return yup.object().shape({
          accountName: yup
            .string()
            .required(props.intl.formatMessage(messages.accountNameRequired)),
          accountPhone: yup
            .string()
            .min(10, props.intl.formatMessage(messages.accountPhoneMinLength))
            .required(props.intl.formatMessage(messages.accountPhoneRequired)),
          accountBillingStreet: yup
            .string()
            .required(
              props.intl.formatMessage(messages.accountBillingStreetRequired)
            ),
          accountBillingCity: yup
            .string()
            .required(
              props.intl.formatMessage(messages.accountBillingCityRequired)
            ),
          accountBillingState: yup
            .string()
            .required(
              props.intl.formatMessage(messages.accountBillingStateRequired)
            ),
          accountBillingCode: yup
            .string()
            .required(
              props.intl.formatMessage(messages.accountBillingCodeRequired)
            ),
          accountBillingCountry: yup
            .string()
            .required(
              props.intl.formatMessage(messages.accountBillingCountryRequired)
            ),
          accountShippingStreet: yup
            .string()
            .required(
              props.intl.formatMessage(messages.accountShippingStreetRequired)
            ),
          accountShippingCity: yup
            .string()
            .required(
              props.intl.formatMessage(messages.accountShippingCityRequired)
            ),
          accountShippingState: yup
            .string()
            .required(
              props.intl.formatMessage(messages.accountShippingStateRequired)
            ),
          accountShippingCode: yup
            .string()
            .required(
              props.intl.formatMessage(messages.accountShippingCodeRequired)
            ),
          accountShippingCountry: yup
            .string()
            .required(
              props.intl.formatMessage(messages.accountShippingCountryRequired)
            ),
          contactFirstName: yup
            .string()
            .required(
              props.intl.formatMessage(messages.contactFirstNameRequired)
            ),
          contactLastName: yup
            .string()
            .required(
              props.intl.formatMessage(messages.contactLastNameRequired)
            ),
          contactTitle: yup
            .string()
            .required(props.intl.formatMessage(messages.contactTitleRequired)),
          contactEmail: yup
            .string()
            .email(props.intl.formatMessage(messages.contactEmailEnterValid))
            .required(props.intl.formatMessage(messages.contactEmailRequired)),
          contactPhone: yup
            .string()
            .min(10, props.intl.formatMessage(messages.contactPhoneMinLength))
            .required(props.intl.formatMessage(messages.contactPhoneRequired)),
        })
      }}
      onSubmit={props.onSubmit}
    >
      {(formikProps) => {
        return (
          <form onSubmit={formikProps.handleSubmit}>
            <Typography variant="h2">
              <FormattedMessage
                id="requestAccount.accountFields"
                defaultMessage="Account"
              />
            </Typography>
            <br />
            <TextField
              autoFocus
              fullWidth
              type="text"
              name="accountName"
              label={props.intl.formatMessage(messages.accountNameLabel)}
              onChange={formikProps.handleChange}
              // onBlur={formikProps.handleBlur}
              helperText={
                formikProps.touched.accountName
                  ? formikProps.errors.accountName
                  : ""
              }
              error={
                formikProps.touched.accountName &&
                Boolean(formikProps.errors.accountName)
              }
              value={formikProps.values.accountName}
            />
            <br />
            <br />
            <TextField
              fullWidth
              type="text"
              name="accountPhone"
              label={props.intl.formatMessage(messages.accountPhoneLabel)}
              onChange={formikProps.handleChange}
              // onBlur={formikProps.handleBlur}
              helperText={
                formikProps.touched.accountPhone
                  ? formikProps.errors.accountPhone
                  : ""
              }
              error={
                formikProps.touched.accountPhone &&
                Boolean(formikProps.errors.accountPhone)
              }
              value={formikProps.values.accountPhone}
            />
            <br />
            <br />
            <br />
            <Typography variant="h2">
              <FormattedMessage
                id="requestAccount.accountBillingAddress"
                defaultMessage="Billing address"
              />
            </Typography>
            <br />
            <TextField
              fullWidth
              type="text"
              name="accountBillingStreet"
              label={props.intl.formatMessage(
                messages.accountBillingStreetLabel
              )}
              onChange={formikProps.handleChange}
              // onBlur={formikProps.handleBlur}
              helperText={
                formikProps.touched.accountBillingStreet
                  ? formikProps.errors.accountBillingStreet
                  : ""
              }
              error={
                formikProps.touched.accountBillingStreet &&
                Boolean(formikProps.errors.accountBillingStreet)
              }
              value={formikProps.values.accountBillingStreet}
            />
            <br />
            <br />
            <TextField
              fullWidth
              type="text"
              name="accountBillingCity"
              label={props.intl.formatMessage(messages.accountBillingCityLabel)}
              onChange={formikProps.handleChange}
              // onBlur={formikProps.handleBlur}
              helperText={
                formikProps.touched.accountBillingCity
                  ? formikProps.errors.accountBillingCity
                  : ""
              }
              error={
                formikProps.touched.accountBillingCity &&
                Boolean(formikProps.errors.accountBillingCity)
              }
              value={formikProps.values.accountBillingCity}
            />
            <br />
            <br />
            <TextField
              fullWidth
              type="text"
              name="accountBillingState"
              label={props.intl.formatMessage(
                messages.accountBillingStateLabel
              )}
              onChange={formikProps.handleChange}
              // onBlur={formikProps.handleBlur}
              helperText={
                formikProps.touched.accountBillingState
                  ? formikProps.errors.accountBillingState
                  : ""
              }
              error={
                formikProps.touched.accountBillingState &&
                Boolean(formikProps.errors.accountBillingState)
              }
              value={formikProps.values.accountBillingState}
            />
            <br />
            <br />
            <TextField
              fullWidth
              type="text"
              name="accountBillingCode"
              label={props.intl.formatMessage(messages.accountBillingCodeLabel)}
              onChange={formikProps.handleChange}
              // onBlur={formikProps.handleBlur}
              helperText={
                formikProps.touched.accountBillingCode
                  ? formikProps.errors.accountBillingCode
                  : ""
              }
              error={
                formikProps.touched.accountBillingCode &&
                Boolean(formikProps.errors.accountBillingCode)
              }
              value={formikProps.values.accountBillingCode}
            />
            <br />
            <br />
            <TextField
              fullWidth
              type="text"
              name="accountBillingCountry"
              label={props.intl.formatMessage(
                messages.accountBillingCountryLabel
              )}
              onChange={formikProps.handleChange}
              // onBlur={formikProps.handleBlur}
              helperText={
                formikProps.touched.accountBillingCountry
                  ? formikProps.errors.accountBillingCountry
                  : ""
              }
              error={
                formikProps.touched.accountBillingCountry &&
                Boolean(formikProps.errors.accountBillingCountry)
              }
              value={formikProps.values.accountBillingCountry}
            />
            <br />
            <br />
            <br />
            <Typography variant="h2">
              <FormattedMessage
                id="requestAccount.accountShippingAddress"
                defaultMessage="Shipping address"
              />
            </Typography>
            <br />
            <TextField
              fullWidth
              type="text"
              name="accountShippingStreet"
              label={props.intl.formatMessage(
                messages.accountShippingStreetLabel
              )}
              onChange={formikProps.handleChange}
              // onBlur={formikProps.handleBlur}
              helperText={
                formikProps.touched.accountShippingStreet
                  ? formikProps.errors.accountShippingStreet
                  : ""
              }
              error={
                formikProps.touched.accountShippingStreet &&
                Boolean(formikProps.errors.accountShippingStreet)
              }
              value={formikProps.values.accountShippingStreet}
            />
            <br />
            <br />
            <TextField
              fullWidth
              type="text"
              name="accountShippingCity"
              label={props.intl.formatMessage(
                messages.accountShippingCityLabel
              )}
              onChange={formikProps.handleChange}
              // onBlur={formikProps.handleBlur}
              helperText={
                formikProps.touched.accountShippingCity
                  ? formikProps.errors.accountShippingCity
                  : ""
              }
              error={
                formikProps.touched.accountShippingCity &&
                Boolean(formikProps.errors.accountShippingCity)
              }
              value={formikProps.values.accountShippingCity}
            />
            <br />
            <br />
            <TextField
              fullWidth
              type="text"
              name="accountShippingState"
              label={props.intl.formatMessage(
                messages.accountShippingStateLabel
              )}
              onChange={formikProps.handleChange}
              // onBlur={formikProps.handleBlur}
              helperText={
                formikProps.touched.accountShippingState
                  ? formikProps.errors.accountShippingState
                  : ""
              }
              error={
                formikProps.touched.accountShippingState &&
                Boolean(formikProps.errors.accountShippingState)
              }
              value={formikProps.values.accountShippingState}
            />
            <br />
            <br />
            <TextField
              fullWidth
              type="text"
              name="accountShippingCode"
              label={props.intl.formatMessage(
                messages.accountShippingCodeLabel
              )}
              onChange={formikProps.handleChange}
              // onBlur={formikProps.handleBlur}
              helperText={
                formikProps.touched.accountShippingCode
                  ? formikProps.errors.accountShippingCode
                  : ""
              }
              error={
                formikProps.touched.accountShippingCode &&
                Boolean(formikProps.errors.accountShippingCode)
              }
              value={formikProps.values.accountShippingCode}
            />
            <br />
            <br />
            <TextField
              fullWidth
              type="text"
              name="accountShippingCountry"
              label={props.intl.formatMessage(
                messages.accountShippingCountryLabel
              )}
              onChange={formikProps.handleChange}
              // onBlur={formikProps.handleBlur}
              helperText={
                formikProps.touched.accountShippingCountry
                  ? formikProps.errors.accountShippingCountry
                  : ""
              }
              error={
                formikProps.touched.accountShippingCountry &&
                Boolean(formikProps.errors.accountShippingCountry)
              }
              value={formikProps.values.accountShippingCountry}
            />
            <br />
            <br />
            <br />
            <Typography variant="h2">
              <FormattedMessage
                id="requestAccount.contactFields"
                defaultMessage="Contact"
              />
            </Typography>
            <br />
            <TextField
              fullWidth
              type="text"
              name="contactFirstName"
              label={props.intl.formatMessage(messages.contactFirstNameLabel)}
              onChange={formikProps.handleChange}
              // onBlur={formikProps.handleBlur}
              helperText={
                formikProps.touched.contactFirstName
                  ? formikProps.errors.contactFirstName
                  : ""
              }
              error={
                formikProps.touched.contactFirstName &&
                Boolean(formikProps.errors.contactFirstName)
              }
              value={formikProps.values.contactFirstName}
            />
            <br />
            <br />
            <TextField
              fullWidth
              type="text"
              name="contactLastName"
              label={props.intl.formatMessage(messages.contactLastNameLabel)}
              onChange={formikProps.handleChange}
              // onBlur={formikProps.handleBlur}
              helperText={
                formikProps.touched.contactLastName
                  ? formikProps.errors.contactLastName
                  : ""
              }
              error={
                formikProps.touched.contactLastName &&
                Boolean(formikProps.errors.contactLastName)
              }
              value={formikProps.values.contactLastName}
            />
            <br />
            <br />
            <TextField
              fullWidth
              type="text"
              name="contactTitle"
              label={props.intl.formatMessage(messages.contactTitleLabel)}
              onChange={formikProps.handleChange}
              // onBlur={formikProps.handleBlur}
              helperText={
                formikProps.touched.contactTitle
                  ? formikProps.errors.contactTitle
                  : ""
              }
              error={
                formikProps.touched.contactTitle &&
                Boolean(formikProps.errors.contactTitle)
              }
              value={formikProps.values.contactTitle}
            />
            <br />
            <br />
            <TextField
              fullWidth
              type="text"
              name="contactEmail"
              label={props.intl.formatMessage(messages.contactEmailLabel)}
              onChange={formikProps.handleChange}
              // onBlur={formikProps.handleBlur}
              helperText={
                formikProps.touched.contactEmail
                  ? formikProps.errors.contactEmail
                  : ""
              }
              error={
                formikProps.touched.contactEmail &&
                Boolean(formikProps.errors.contactEmail)
              }
              value={formikProps.values.contactEmail}
            />
            <br />
            <br />
            <TextField
              fullWidth
              type="text"
              name="contactPhone"
              label={props.intl.formatMessage(messages.contactPhoneLabel)}
              onChange={formikProps.handleChange}
              // onBlur={formikProps.handleBlur}
              helperText={
                formikProps.touched.contactPhone
                  ? formikProps.errors.contactPhone
                  : ""
              }
              error={
                formikProps.touched.contactPhone &&
                Boolean(formikProps.errors.contactPhone)
              }
              value={formikProps.values.contactPhone}
            />
            <br />
            <br />
            <br />
            <Actions>
              <Button
                color="primary"
                variant="contained"
                size="large"
                type="submit"
                disabled={formikProps.isSubmitting}
              >
                <FormattedMessage
                  id="requestAccount.requestAccount"
                  defaultMessage="Request account"
                />
              </Button>
            </Actions>
          </form>
        )
      }}
    </Formik>
  )
}

interface RequestAccountProps extends RouteComponentProps, IntlComponentProps {}

interface RequestAccountState {
  error?: string
  showError: boolean
  submitted: boolean
}

class RequestAccount extends Component<
  RequestAccountProps,
  RequestAccountState
> {
  constructor(props: RequestAccountProps) {
    super(props)
    this.state = {
      showError: false,
      submitted: false,
    }
  }
  render() {
    if (this.state.submitted) {
      return (
        <Box>
          <Typography variant="h2">
            <FormattedMessage
              id="requestAccount.confirmation"
              defaultMessage="Thanks! We will be in touch shortly!"
            />
          </Typography>
        </Box>
      )
    } else {
      return (
        <Box>
          <Typography variant="h2">
            <FormattedMessage
              id="requestAccount.instructionsHeading"
              defaultMessage="Please submit this form to request account."
            />
          </Typography>
          <br />
          <Typography variant="body1">
            <FormattedMessage
              id="requestAccount.instructionsDetails"
              defaultMessage="Someone will follow up as soon as possible. Need help? {contactUs}"
              values={{
                contactUs: (
                  <Link href="https://www.poly.ca/contact/" target="_blank">
                    <FormattedMessage
                      id="requestAccount.contactUs"
                      defaultMessage="Contact us"
                    />
                  </Link>
                ),
              }}
            />
          </Typography>
          <br />
          <RequestAccountForm
            intl={this.props.intl}
            onSubmit={(values, actions) => {
              const req = request.post(
                `${process.env.REACT_APP_POLYINC_API_PREFIX_URL}/v1/accounts`
              )
              req.send(values)
              req.end((error, response) => {
                actions.setSubmitting(false)
                if (error || response.status !== 204) {
                  this.setState({
                    error: this.props.intl.formatMessage(
                      messages.couldNotRequestAccount
                    ),
                    showError: true,
                  })
                } else {
                  actions.resetForm()
                  this.setState({
                    error: undefined,
                    showError: false,
                    submitted: true,
                  })
                }
              })
            }}
          />
          <StatusSnackbar
            open={this.state.showError}
            handleClose={() => {
              this.setState({
                showError: false,
              })
            }}
            message={this.state.error}
          />
        </Box>
      )
    }
  }
}

export default injectIntl(RequestAccount)
