import React, { Component, ReactNode } from "react"
import { Theme, withTheme } from "@material-ui/core/styles"
import styled from "styled-components"
import {
  injectIntl,
  WrappedComponentProps as IntlComponentProps,
} from "react-intl"
import { Link as RouterLink } from "react-router-dom"
import { Link } from "@material-ui/core"

interface ContainerProps {
  theme: Theme
}

const Container = withTheme(
  styled.div`
    max-width: ${(props: ContainerProps) =>
      props.theme.breakpoints.values.sm}px;
    margin-top: 20px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 20px;
    padding: 20px;
    border: solid 4px
      ${(props: ContainerProps) => props.theme.palette.secondary.main};
    @media only screen and (max-width: ${(props: ContainerProps) =>
        props.theme.breakpoints.values.sm}px) {
      padding: 0px;
      border: none;
    }
  `
)

const Header = styled.div`
  position: relative;
  text-align: center;
`

const Logo = styled.img`
  height: 72px;
`

interface BoxProps extends IntlComponentProps {
  children?: ReactNode
}

class Box extends Component<BoxProps> {
  render() {
    return (
      <Container>
        <Header>
          <Link component={RouterLink} to={`/${this.props.intl.locale}`}>
            <Logo height="40px" src="/static/media/logo.svg" />
          </Link>
        </Header>
        <br />
        <br />
        {this.props.children}
      </Container>
    )
  }
}

export default injectIntl(Box)
