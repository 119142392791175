import React, { FunctionComponent, Component } from "react"
import { RouteComponentProps } from "react-router-dom"
import {
  injectIntl,
  defineMessages,
  FormattedMessage,
  WrappedComponentProps as IntlComponentProps,
} from "react-intl"
import { Typography, Button, TextField } from "@material-ui/core"
import { Formik, FormikHelpers } from "formik"
import * as yup from "yup"
import styled from "styled-components"
import request from "superagent"
import Box from "../Box"
import StatusSnackbar from "../StatusSnackbar"

const messages = defineMessages({
  firstNameLabel: {
    id: "guestLogin.firstNameLabel",
    defaultMessage: "First name",
  },
  firstNameEnterValid: {
    id: "guestLogin.firstNameEnterValid",
    defaultMessage: "Enter valid first name",
  },
  firstNameRequired: {
    id: "guestLogin.firstNameRequired",
    defaultMessage: "First name required",
  },
  lastNameLabel: {
    id: "guestLogin.lastNameLabel",
    defaultMessage: "Last name",
  },
  lastNameEnterValid: {
    id: "guestLogin.lastNameEnterValid",
    defaultMessage: "Enter valid last name",
  },
  lastNameRequired: {
    id: "guestLogin.lastNameRequired",
    defaultMessage: "Last name required",
  },
  emailLabel: {
    id: "guestLogin.emailLabel",
    defaultMessage: "Email",
  },
  emailEnterValid: {
    id: "guestLogin.emailEnterValid",
    defaultMessage: "Enter valid email",
  },
  emailRequired: {
    id: "guestLogin.emailRequired",
    defaultMessage: "Email required",
  },
  accountLogin: {
    id: "guestLogin.accountLogin",
    defaultMessage: "Please use account login",
  },
  privilegedAccount: {
    id: "login.privilegedAccount",
    defaultMessage: "Please use account login",
  },
  couldNotRequestMagicLink: {
    id: "guestLogin.couldNotRequestMagicLink",
    defaultMessage: "Could not request magic link",
  },
})

const Actions = styled.div`
  text-align: right;
`

interface GuestLoginFormValues {
  firstName: string
  lastName: string
  email: string
  locale: string
}

interface GuestLoginFormProps extends IntlComponentProps {
  onSubmit: (
    values: GuestLoginFormValues,
    actions: FormikHelpers<GuestLoginFormValues>
  ) => void
}

export const GuestLoginForm: FunctionComponent<GuestLoginFormProps> = (
  props
) => {
  const initialValues: GuestLoginFormValues = {
    firstName: "",
    lastName: "",
    email: "",
    locale: props.intl.locale,
  }
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={() => {
        return yup.object().shape({
          firstName: yup
            .string()
            .required(props.intl.formatMessage(messages.firstNameRequired)),
          lastName: yup
            .string()
            .required(props.intl.formatMessage(messages.lastNameRequired)),
          email: yup
            .string()
            .email(props.intl.formatMessage(messages.emailEnterValid))
            .required(props.intl.formatMessage(messages.emailRequired)),
        })
      }}
      onSubmit={props.onSubmit}
    >
      {(formikProps) => {
        return (
          <form onSubmit={formikProps.handleSubmit}>
            <TextField
              autoFocus
              fullWidth
              type="text"
              name="firstName"
              label={props.intl.formatMessage(messages.firstNameLabel)}
              onChange={formikProps.handleChange}
              // onBlur={formikProps.handleBlur}
              helperText={
                formikProps.touched.firstName
                  ? formikProps.errors.firstName
                  : ""
              }
              error={
                formikProps.touched.firstName &&
                Boolean(formikProps.errors.firstName)
              }
              value={formikProps.values.firstName}
            />
            <br />
            <br />
            <TextField
              fullWidth
              type="text"
              name="lastName"
              label={props.intl.formatMessage(messages.lastNameLabel)}
              onChange={formikProps.handleChange}
              // onBlur={formikProps.handleBlur}
              helperText={
                formikProps.touched.lastName ? formikProps.errors.lastName : ""
              }
              error={
                formikProps.touched.lastName &&
                Boolean(formikProps.errors.lastName)
              }
              value={formikProps.values.lastName}
            />
            <br />
            <br />
            <TextField
              fullWidth
              type="text"
              name="email"
              label={props.intl.formatMessage(messages.emailLabel)}
              onChange={formikProps.handleChange}
              // onBlur={formikProps.handleBlur}
              helperText={
                formikProps.touched.email ? formikProps.errors.email : ""
              }
              error={
                formikProps.touched.email && Boolean(formikProps.errors.email)
              }
              value={formikProps.values.email}
            />
            <br />
            <br />
            <br />
            <Actions>
              <Button
                color="primary"
                variant="contained"
                size="large"
                type="submit"
                disabled={formikProps.isSubmitting}
              >
                <FormattedMessage
                  id="guestLogin.requestMagicLink"
                  defaultMessage="Request magic link"
                />
              </Button>
            </Actions>
          </form>
        )
      }}
    </Formik>
  )
}

interface GuestLoginProps extends RouteComponentProps, IntlComponentProps {}

interface GuestLoginState {
  error?: string
  showError: boolean
  completed: boolean
}

class GuestLogin extends Component<GuestLoginProps, GuestLoginState> {
  constructor(props: GuestLoginProps) {
    super(props)
    this.state = {
      showError: false,
      completed: false,
    }
  }
  render() {
    if (this.state.completed) {
      return (
        <Box>
          <Typography variant="h2">
            <FormattedMessage
              id="guestLogin.pleaseCheckYourEmails"
              defaultMessage="Please check your emails"
            />
          </Typography>
        </Box>
      )
    } else {
      return (
        <Box>
          <Typography variant="h2">
            <FormattedMessage
              id="guestLogin.enterNameAndEmail"
              defaultMessage="Please enter your name and email"
            />
          </Typography>
          <br />
          <FormattedMessage
            id="guestLogin.instruction"
            defaultMessage="We will send you a magic link by email. Click it to send us file."
          />
          <br />
          <br />
          <GuestLoginForm
            intl={this.props.intl}
            onSubmit={(values, actions) => {
              const req = request.post(
                `${process.env.REACT_APP_POLYINC_API_PREFIX_URL}/v1/login/guest`
              )
              req.send(values)
              req.end((error, response) => {
                actions.setSubmitting(false)
                if (error || response.status !== 204) {
                  let error: string
                  if (response?.status === 401) {
                    error = this.props.intl.formatMessage(messages.accountLogin)
                  } else if (response?.status === 403) {
                    error = this.props.intl.formatMessage(
                      messages.privilegedAccount
                    )
                  } else {
                    error = this.props.intl.formatMessage(
                      messages.couldNotRequestMagicLink
                    )
                  }
                  this.setState({
                    error: error,
                    showError: true,
                  })
                } else {
                  actions.resetForm()
                  this.setState({
                    error: undefined,
                    showError: false,
                    completed: true,
                  })
                }
              })
            }}
          />
          <StatusSnackbar
            open={this.state.showError}
            handleClose={() => {
              this.setState({
                showError: false,
              })
            }}
            message={this.state.error}
          />
        </Box>
      )
    }
  }
}

export default injectIntl(GuestLogin)
