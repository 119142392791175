import React, { Component } from "react"
import { Snackbar, IconButton } from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"

interface StatusSnackbarProps {
  open: boolean
  message?: string
  handleClose: () => void
}

export default class StatusSnackbar extends Component<StatusSnackbarProps> {
  constructor(props: StatusSnackbarProps) {
    super(props)
    this.handleClose = this.handleClose.bind(this)
  }
  handleClose(event: React.SyntheticEvent | React.MouseEvent, reason?: string) {
    if (reason !== "clickaway") {
      this.props.handleClose()
    }
  }
  render() {
    return (
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={this.props.open}
        autoHideDuration={6000}
        onClose={this.handleClose}
        message={this.props.message}
        action={
          <IconButton key="close" color="inherit" onClick={this.handleClose}>
            <CloseIcon />
          </IconButton>
        }
      />
    )
  }
}
