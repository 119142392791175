import React, { Component } from "react"
import ReactDropzone, {
  DropzoneRootProps,
  DropzoneOptions,
  FileRejection,
} from "react-dropzone"
import { StyledComponent } from "styled-components"
import request from "superagent"

export interface DropContainerProps extends DropzoneRootProps {}

export interface DropLoadingProps {
  uploading: boolean
  percent: number
}

export interface File {
  fieldname: string
  originalname: string
  encoding: string
  mimetype: string
  destination: string
  filename: string
  path: string
  size: number
}

export class DropRejectionError extends Error {
  public fileRejections: FileRejection[]
  constructor(error: string, fileRejections: FileRejection[]) {
    super(error)
    this.fileRejections = fileRejections
  }
}

export class DropInProgressError extends Error {}

export class DropUploadError extends Error {
  public requestError: any
  constructor(error: string, requestError: any) {
    super(error)
    this.requestError = requestError
  }
}

interface DropContextProviderProps {}

interface DropContextProviderState {
  files: File[]
}

export interface DropContextProps {
  files: File[]
  addFiles?: (files: File[]) => void
  removeFile?: (originalName: string) => void
  reset?: () => void
  open?: () => void
}

const defaultDropContext: DropContextProps = {
  files: [],
}

const DropContext = React.createContext(defaultDropContext)

export const DropContextConsumer = DropContext.Consumer

export class DropContextProvider extends Component<
  DropContextProviderProps,
  DropContextProviderState
> {
  constructor(props: DropProps) {
    super(props)
    this.state = {
      files: [],
    }
  }
  render() {
    return (
      <DropContext.Provider
        value={{
          files: this.state.files,
          addFiles: (files: File[]) => {
            this.setState({
              files: this.state.files.concat(files),
            })
          },
          removeFile: (originalName) => {
            let files: File[] = []
            for (const file of this.state.files) {
              if (file.originalname !== originalName) {
                files.push(file)
              }
            }
            this.setState({
              files: files,
            })
          },
          reset: () => {
            this.setState({
              files: [],
            })
          },
        }}
      >
        {this.props.children}
      </DropContext.Provider>
    )
  }
}

interface DropProps
  extends Pick<DropzoneOptions, "accept" | "minSize" | "maxSize" | "noClick"> {
  container: StyledComponent<"div", any, DropContainerProps, never>
  loading: StyledComponent<"div", any, DropLoadingProps, never>
  endpoint: string
  authorization?: string
  onUploadInitiated?: () => void
  onUploadProgress?: (percent: number) => void
  onUploadCompleted?: (files: File[]) => void
  onFilesChanged?: (files: File[]) => void
  onError: (error: Error) => void
}

interface DropState {
  uploading: boolean
  percent: number
  files: File[]
}

class Drop extends Component<DropProps, DropState> {
  constructor(props: DropProps) {
    super(props)
    this.state = {
      uploading: false,
      percent: 0,
      files: [],
    }
  }
  checkIfDuplicate(files: File[], name: string) {
    for (const file of files) {
      if (file.originalname === name) {
        return true
      }
    }
    return false
  }
  render() {
    const Container = this.props.container
    const Loading = this.props.loading
    return (
      <DropContextConsumer>
        {(dropContext) => {
          return (
            <ReactDropzone
              accept={this.props.accept}
              minSize={this.props.minSize}
              maxSize={this.props.maxSize}
              noClick={this.props.noClick}
              onDrop={(acceptedFiles, fileRejections) => {
                if (this.state.uploading) {
                  this.props.onError(
                    new DropInProgressError("Upload in progress")
                  )
                  return
                }
                let validatedAcceptedFiles: typeof acceptedFiles = []
                // Skip duplicates
                for (const acceptedFile of acceptedFiles) {
                  if (
                    this.checkIfDuplicate(dropContext.files, acceptedFile.name)
                  ) {
                    fileRejections = fileRejections.concat({
                      file: acceptedFile,
                      errors: [
                        {
                          code: "file-duplicate",
                          message: `File is duplicate of ${acceptedFile.name}`,
                        },
                      ],
                    })
                  } else {
                    validatedAcceptedFiles.push(acceptedFile)
                  }
                }
                if (fileRejections.length > 0) {
                  this.props.onError(
                    new DropRejectionError("Files rejected", fileRejections)
                  )
                  return
                }
                if (validatedAcceptedFiles.length > 0) {
                  if (this.props.onUploadInitiated) {
                    this.props.onUploadInitiated()
                  }
                  const req = request.post(this.props.endpoint)
                  if (this.props.authorization) {
                    req.set("authorization", this.props.authorization)
                  }
                  validatedAcceptedFiles.forEach((file) => {
                    req.attach(file.name, file)
                  })
                  req.on("progress", (event) => {
                    let percent = event.percent ? event.percent : 0
                    this.setState({
                      uploading: true,
                      percent: percent,
                    })
                    if (this.props.onUploadProgress) {
                      this.props.onUploadProgress(percent)
                    }
                  })
                  req.end((error, response) => {
                    if (error) {
                      this.setState({
                        uploading: false,
                      })
                      this.props.onError(
                        new DropUploadError("Upload failed", error)
                      )
                      return
                    }
                    if (response.status !== 200) {
                      this.setState({
                        uploading: false,
                      })
                      this.props.onError(
                        new DropUploadError(
                          "Upload failed",
                          new Error(
                            `Server returned status code ${response.status}`
                          )
                        )
                      )
                      return
                    }
                    this.setState({
                      uploading: false,
                    })
                    if (dropContext.addFiles) {
                      dropContext.addFiles(response.body.files)
                    }
                    if (this.props.onUploadCompleted) {
                      this.props.onUploadCompleted(response.body.files)
                    }
                  })
                }
              }}
            >
              {({
                getRootProps,
                getInputProps,
                isDragActive,
                isDragAccept,
                isDragReject,
                open,
              }) => {
                dropContext.open = open
                return (
                  <Container
                    {...getRootProps({
                      isDragActive,
                      isDragAccept,
                      isDragReject,
                    })}
                  >
                    <Loading
                      uploading={this.state.uploading}
                      percent={this.state.percent}
                    />
                    <input {...getInputProps()} />
                    {this.props.children}
                  </Container>
                )
              }}
            </ReactDropzone>
          )
        }}
      </DropContextConsumer>
    )
  }
}

export default Drop
