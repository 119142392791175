import "typeface-open-sans"
import { createMuiTheme } from "@material-ui/core/styles"
import { Shadows } from "@material-ui/core/styles/shadows"

const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    primary: {
      main: "#ef3e42",
    },
    secondary: {
      main: "#636466",
    },
    error: {
      main: "#636466",
    },
    text: {
      primary: "#636466",
    },
    background: {
      default: "#FFFFFF",
      paper: "#FFFFFF",
    },
  },
  typography: {
    fontFamily: "'Open Sans', sans-serif",
    htmlFontSize: 14,
    body1: {
      color: "#636466",
    },
    h1: {
      fontSize: "2rem",
      fontWeight: "bold",
      color: "#636466",
    },
    h2: {
      fontSize: "1.5rem",
      fontWeight: "bold",
      color: "#636466",
    },
    h3: {
      fontSize: "1rem",
      fontWeight: "bold",
      color: "#636466",
    },
  },
  shadows: Array(25).fill("none") as Shadows,
})

theme.overrides = {
  MuiAccordion: {
    root: {
      backgroundColor: "transparent",
      "&::before": {
        backgroundColor: "transparent",
      },
    },
  },
  MuiAccordionSummary: {
    root: {
      padding: "0px",
    },
  },
  MuiButton: {
    root: {
      borderRadius: 0,
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    sizeSmall: {
      minHeight: "20px",
      padding: "5px 20px",
    },
    sizeLarge: {
      minHeight: "80px",
      padding: "20px 80px",
    },
  },
  MuiLink: {
    root: {
      fontWeight: "bold",
    },
    underlineHover: {
      textDecoration: "underline",
    },
  },
  MuiPaper: {
    rounded: {
      borderRadius: 0,
    },
  },
  MuiSnackbar: {
    root: {
      minHeight: "60px",
      alignItems: "stretch",
    },
    anchorOriginBottomLeft: {
      [theme.breakpoints.up("sm")]: {
        bottom: "20px",
        left: "20px",
      },
      [theme.breakpoints.down("sm")]: {
        right: "100px",
        bottom: "20px",
        left: "20px",
      },
    },
  },
  MuiSnackbarContent: {
    root: {
      backgroundColor: "#636466",
      paddingTop: "4px",
      paddingBottom: "4px",
      borderRadius: 0,
    },
  },
}

export default theme
