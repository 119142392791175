import React, { Fragment } from "react"
import { Link, RouteComponentProps, withRouter } from "react-router-dom"
import {
  injectIntl,
  FormattedMessage,
  WrappedComponentProps as IntlComponentProps,
} from "react-intl"
import * as Sentry from "@sentry/browser"

interface NotFoundProps extends RouteComponentProps, IntlComponentProps {}

const NotFound = function (props: NotFoundProps) {
  Sentry.captureException(
    new Error(`Page not found at ${props.location.pathname}`)
  )
  if (props.staticContext) {
    props.staticContext.statusCode = 404
  }
  return (
    <Fragment>
      <FormattedMessage
        id="notFound.pageNotFound"
        defaultMessage="Page not found"
      />{" "}
      <Link to={`/${props.intl.locale}`}>
        <FormattedMessage id="notFound.goTo" defaultMessage="Go to" /> /
      </Link>
    </Fragment>
  )
}

export default injectIntl(withRouter(NotFound))
