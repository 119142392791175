import React, { Component } from "react"
import {
  injectIntl,
  defineMessages,
  WrappedComponentProps as IntlComponentProps,
} from "react-intl"
import { RouteComponentProps, Redirect, withRouter } from "react-router-dom"
import queryString from "query-string"
import cookie from "js-cookie"
import request from "superagent"
import { CircularProgress } from "@material-ui/core"
import styled from "styled-components"
import StatusSnackbar from "./StatusSnackbar"

const messages = defineMessages({
  couldNotLogOut: {
    id: "customAuthProvider.couldNotLogOut",
    defaultMessage: "Could not log out",
  },
})

export const setAccessToken = function (accessToken: string) {
  cookie.set("access_token", accessToken, {
    expires: 30,
    secure: process.env.REACT_APP_COOKIE_SECURE === "true",
  })
}

export const clearAccessToken = function () {
  cookie.remove("access_token")
}

export const getAccessToken = function (staticContext: any) {
  let accessToken: string | undefined = undefined
  if (typeof window === "undefined") {
    if (staticContext?.query?.access_token) {
      accessToken = staticContext.query.access_token
    } else if (staticContext?.cookies?.access_token) {
      accessToken = staticContext.cookies.access_token
    }
  } else {
    const parsedQueryString = queryString.parse(window.location.search)
    if (parsedQueryString.access_token) {
      accessToken = parsedQueryString.access_token as string
      setAccessToken(accessToken)
    } else {
      accessToken = cookie.get("access_token")
    }
  }
  return accessToken
}

export interface ShippingAddress {
  id: string
  name: string
}

export interface Contact {
  accountName: string
  firstName: string
  lastName: string
  shippingAddresses: ShippingAddress[]
}

interface ContextProps {
  accessToken?: string
  contact?: Contact
  setContact?: (contact: Contact) => void
  logout?: () => Promise<boolean>
}

const defaultContext: ContextProps = {}

export const Context = React.createContext(defaultContext)

export const CustomAuthConsumer = Context.Consumer

const LoadingContainer = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

interface CustomAuthProviderProps
  extends IntlComponentProps,
    RouteComponentProps {}

interface CustomAuthProviderState {
  loading: boolean
  redirectTo?: string
  accessToken?: string
  contact?: Contact
  error?: string
  showError: boolean
}

class CustomAuthProvider extends Component<
  CustomAuthProviderProps,
  CustomAuthProviderState
> {
  constructor(props: CustomAuthProviderProps) {
    super(props)
    let accessToken = getAccessToken(props.staticContext)
    if (!accessToken) {
      this.state = {
        loading: false,
        redirectTo: `/${this.props.intl.locale}`,
        showError: false,
      }
    } else {
      this.state = {
        loading: true,
        accessToken: accessToken,
        showError: false,
      }
    }
  }
  clear() {
    clearAccessToken()
    this.setState({
      redirectTo: `/${this.props.intl.locale}`,
      showError: false,
    })
  }
  componentDidMount() {
    if (this.state.accessToken) {
      const req = request.get(
        `${process.env.REACT_APP_POLYINC_API_PREFIX_URL}/v1/contacts/me`
      )
      req.set("authorization", `Bearer ${this.state.accessToken}`)
      req.end((error, response) => {
        if (error || response.status !== 200) {
          this.clear()
        } else {
          this.setState({
            loading: false,
            contact: response.body,
          })
        }
      })
    }
  }
  render() {
    if (this.state.redirectTo) {
      return <Redirect to={this.state.redirectTo} />
    } else if (this.state.loading) {
      return (
        <LoadingContainer>
          <CircularProgress color="secondary" />
        </LoadingContainer>
      )
    } else {
      return (
        <Context.Provider
          value={{
            accessToken: this.state.accessToken,
            contact: this.state.contact,
            setContact: (contact) => {
              this.setState({
                contact: contact,
              })
            },
            logout: async () => {
              return new Promise((resolve) => {
                const req = request.post(
                  `${process.env.REACT_APP_POLYINC_API_PREFIX_URL}/v1/logout`
                )
                req.set("authorization", `Bearer ${this.state.accessToken}`)
                req.end((error, response) => {
                  if (error || response.status !== 204) {
                    this.setState({
                      error: this.props.intl.formatMessage(
                        messages.couldNotLogOut
                      ),
                      showError: true,
                    })
                    resolve(false)
                  } else {
                    this.clear()
                    resolve(true)
                  }
                })
              })
            },
          }}
        >
          {this.props.children}
          <StatusSnackbar
            open={this.state.showError}
            handleClose={() => {
              this.setState({
                showError: false,
              })
            }}
            message={this.state.error}
          />
        </Context.Provider>
      )
    }
  }
}

export default injectIntl(withRouter(CustomAuthProvider))
