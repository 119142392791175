import React, { FunctionComponent, Component, Fragment } from "react"
import { RouteComponentProps } from "react-router-dom"
import {
  injectIntl,
  defineMessages,
  FormattedMessage,
  WrappedComponentProps as IntlComponentProps,
} from "react-intl"
import { Typography, Button, TextField } from "@material-ui/core"
import { Formik, FormikHelpers } from "formik"
import * as yup from "yup"
import styled from "styled-components"
import request from "superagent"
import { Link as RouterLink } from "react-router-dom"
import { Link } from "@material-ui/core"
import { getAccessToken, clearAccessToken } from "../CustomAuthProvider"
import Box from "../Box"
import StatusSnackbar from "../StatusSnackbar"

const messages = defineMessages({
  emailLabel: {
    id: "reset.emailLabel",
    defaultMessage: "Email",
  },
  emailEnterValid: {
    id: "reset.emailEnterValid",
    defaultMessage: "Enter valid email",
  },
  emailRequired: {
    id: "reset.emailRequired",
    defaultMessage: "Email required",
  },
  passwordLabel: {
    id: "login.passwordLabel",
    defaultMessage: "Password",
  },
  passwordMinLength: {
    id: "login.passwordMinLength",
    defaultMessage: "Password must contain at least 6 characters",
  },
  passwordRequired: {
    id: "login.passwordRequired",
    defaultMessage: "Password required",
  },
  passwordConfirmationLabel: {
    id: "login.passwordConfirmationLabel",
    defaultMessage: "Password confirmation",
  },
  passwordConfirmationMatch: {
    id: "login.passwordConfirmationMatch",
    defaultMessage: "Password confirmation must match password",
  },
  passwordConfirmationRequired: {
    id: "login.passwordConfirmationRequired",
    defaultMessage: "Password confirmation required",
  },
  contactNotFound: {
    id: "reset.contactNotFound",
    defaultMessage: "Could not find contact",
  },
  couldNotRequestPasswordReset: {
    id: "reset.couldNotRequestPasswordReset",
    defaultMessage: "Could not request password reset",
  },
  guestAccount: {
    id: "reset.guestAccount",
    defaultMessage: "Please use guest login",
  },
  couldNotChangePassword: {
    id: "reset.couldNotChangePassword",
    defaultMessage: "Could not change password",
  },
})

const Actions = styled.div`
  text-align: right;
`

interface RequestResetFormValues {
  email: string
  locale: string
}

interface RequestResetFormProps extends IntlComponentProps {
  onSubmit: (
    values: RequestResetFormValues,
    actions: FormikHelpers<RequestResetFormValues>
  ) => void
}

export const RequestResetForm: FunctionComponent<RequestResetFormProps> = (
  props
) => {
  const initialValues: RequestResetFormValues = {
    email: "",
    locale: props.intl.locale,
  }
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={() => {
        return yup.object().shape({
          email: yup
            .string()
            .email(props.intl.formatMessage(messages.emailEnterValid))
            .required(props.intl.formatMessage(messages.emailRequired)),
        })
      }}
      onSubmit={props.onSubmit}
    >
      {(formikProps) => {
        return (
          <form onSubmit={formikProps.handleSubmit}>
            <TextField
              autoFocus
              fullWidth
              type="text"
              name="email"
              label={props.intl.formatMessage(messages.emailLabel)}
              onChange={formikProps.handleChange}
              // onBlur={formikProps.handleBlur}
              helperText={
                formikProps.touched.email ? formikProps.errors.email : ""
              }
              error={
                formikProps.touched.email && Boolean(formikProps.errors.email)
              }
              value={formikProps.values.email}
            />
            <br />
            <br />
            <br />
            <Actions>
              <Button
                color="primary"
                variant="contained"
                size="large"
                type="submit"
                disabled={formikProps.isSubmitting}
              >
                <FormattedMessage
                  id="reset.requestPasswordReset"
                  defaultMessage="Request password reset"
                />
              </Button>
            </Actions>
          </form>
        )
      }}
    </Formik>
  )
}

interface ChangePasswordFormValues {
  password: string
  passwordConfirmation: string
}

interface ChangePasswordFormProps extends IntlComponentProps {
  onSubmit: (
    values: ChangePasswordFormValues,
    actions: FormikHelpers<ChangePasswordFormValues>
  ) => void
}

export const ChangePasswordForm: FunctionComponent<ChangePasswordFormProps> = (
  props
) => {
  const initialValues: ChangePasswordFormValues = {
    password: "",
    passwordConfirmation: "",
  }
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={() => {
        return yup.object().shape({
          password: yup
            .string()
            .min(6, props.intl.formatMessage(messages.passwordMinLength))
            .required(props.intl.formatMessage(messages.passwordRequired)),
          passwordConfirmation: yup
            .string()
            .test(
              "password-must-match",
              props.intl.formatMessage(messages.passwordConfirmationMatch),
              function (value) {
                return value === this.parent.password
              }
            ),
        })
      }}
      onSubmit={props.onSubmit}
    >
      {(formikProps) => {
        return (
          <form onSubmit={formikProps.handleSubmit}>
            <TextField
              autoFocus
              fullWidth
              type="password"
              name="password"
              label={props.intl.formatMessage(messages.passwordLabel)}
              onChange={formikProps.handleChange}
              // onBlur={formikProps.handleBlur}
              helperText={
                formikProps.touched.password ? formikProps.errors.password : ""
              }
              error={
                formikProps.touched.password &&
                Boolean(formikProps.errors.password)
              }
              value={formikProps.values.password}
            />
            <br />
            <br />
            <TextField
              fullWidth
              type="password"
              name="passwordConfirmation"
              label={props.intl.formatMessage(
                messages.passwordConfirmationLabel
              )}
              onChange={formikProps.handleChange}
              // onBlur={formikProps.handleBlur}
              helperText={
                formikProps.touched.passwordConfirmation
                  ? formikProps.errors.passwordConfirmation
                  : ""
              }
              error={
                formikProps.touched.passwordConfirmation &&
                Boolean(formikProps.errors.passwordConfirmation)
              }
              value={formikProps.values.passwordConfirmation}
            />
            <br />
            <br />
            <br />
            <Actions>
              <Button
                color="primary"
                variant="contained"
                size="large"
                type="submit"
                disabled={formikProps.isSubmitting}
              >
                <FormattedMessage
                  id="reset.changePassword"
                  defaultMessage="Change password"
                />
              </Button>
            </Actions>
          </form>
        )
      }}
    </Formik>
  )
}

interface ResetProps extends RouteComponentProps, IntlComponentProps {}

interface ResetState {
  error?: string
  showError: boolean
  initiated: boolean
  expiredToken: boolean
  email?: string
  completed: boolean
}

class Reset extends Component<ResetProps, ResetState> {
  constructor(props: ResetProps) {
    super(props)
    this.state = {
      showError: false,
      initiated: false,
      expiredToken: false,
      completed: false,
    }
  }
  render() {
    let accessToken = getAccessToken(this.props.staticContext)
    if (accessToken) {
      let node: React.ReactNode
      if (this.state.expiredToken) {
        node = (
          <Fragment>
            <Typography variant="h2">
              <FormattedMessage
                id="reset.expiredToken"
                defaultMessage="Sorry, password reset link has expired"
              />
            </Typography>
            <br />
            <br />
            <br />
            <Actions>
              <Link
                component={RouterLink}
                to={`/${this.props.intl.locale}/reset`}
                onClick={() => {
                  clearAccessToken()
                  this.setState({
                    showError: false,
                    initiated: false,
                    expiredToken: false,
                    completed: false,
                  })
                }}
              >
                <Button color="primary" variant="contained" size="large">
                  <FormattedMessage
                    id="reset.tryAgain"
                    defaultMessage="Try again..."
                  />
                </Button>
              </Link>
            </Actions>
          </Fragment>
        )
      } else if (this.state.completed) {
        node = (
          <Fragment>
            <Typography variant="h2">
              <FormattedMessage
                id="reset.passwordChanged"
                defaultMessage="Password changed"
              />
            </Typography>
            <br />
            <br />
            <br />
            <Actions>
              <Link
                component={RouterLink}
                to={`/${this.props.intl.locale}/login`}
              >
                <Button color="primary" variant="contained" size="large">
                  <FormattedMessage
                    id="reset.logIn"
                    defaultMessage="Log in..."
                  />
                </Button>
              </Link>
            </Actions>
          </Fragment>
        )
      } else {
        node = (
          <Fragment>
            <Typography variant="h2">
              <FormattedMessage
                id="reset.enterPassword"
                defaultMessage="Enter password"
              />
            </Typography>
            <br />
            <ChangePasswordForm
              intl={this.props.intl}
              onSubmit={(values, actions) => {
                const req = request.post(
                  `${process.env.REACT_APP_POLYINC_API_PREFIX_URL}/v1/contacts/me/password`
                )
                req.set("authorization", `Bearer ${accessToken}`)
                req.send(values)
                req.end((error, response) => {
                  actions.setSubmitting(false)
                  if (error || response.status !== 204) {
                    let expiredToken = false
                    let error: string | undefined = undefined
                    let showError: boolean = false
                    if (response?.status === 401) {
                      expiredToken = true
                    } else {
                      error = this.props.intl.formatMessage(
                        messages.couldNotChangePassword
                      )
                      showError = true
                    }
                    this.setState({
                      error: error,
                      showError: showError,
                      expiredToken: expiredToken,
                    })
                  } else {
                    actions.resetForm()
                    this.setState({
                      error: undefined,
                      showError: false,
                      completed: true,
                    })
                  }
                })
              }}
            />
          </Fragment>
        )
      }
      return (
        <Box>
          {node}
          <StatusSnackbar
            open={this.state.showError}
            handleClose={() => {
              this.setState({
                showError: false,
              })
            }}
            message={this.state.error}
          />
        </Box>
      )
    } else {
      let node: React.ReactNode
      if (this.state.initiated) {
        node = (
          <Typography variant="h2">
            <FormattedMessage
              id="reset.pleaseCheckYourEmails"
              defaultMessage="Please check your emails"
            />
          </Typography>
        )
      } else {
        node = (
          <Fragment>
            <Typography variant="h2">
              <FormattedMessage
                id="reset.enterEmail"
                defaultMessage="Enter email"
              />
            </Typography>
            <br />
            <RequestResetForm
              intl={this.props.intl}
              onSubmit={(values, actions) => {
                const req = request.post(
                  `${process.env.REACT_APP_POLYINC_API_PREFIX_URL}/v1/contacts/me/reset`
                )
                req.send(values)
                req.end((error, response) => {
                  actions.setSubmitting(false)
                  if (error || response.status !== 204) {
                    let error: string
                    if (response?.status === 401) {
                      error = this.props.intl.formatMessage(
                        messages.contactNotFound
                      )
                    } else if (response?.status === 403) {
                      error = this.props.intl.formatMessage(
                        messages.guestAccount
                      )
                    } else {
                      error = this.props.intl.formatMessage(
                        messages.couldNotRequestPasswordReset
                      )
                    }
                    this.setState({
                      error: error,
                      showError: true,
                    })
                  } else {
                    actions.resetForm()
                    this.setState({
                      error: undefined,
                      showError: false,
                      initiated: true,
                      email: values.email,
                    })
                  }
                })
              }}
            />
          </Fragment>
        )
      }
      return (
        <Box>
          {node}
          <StatusSnackbar
            open={this.state.showError}
            handleClose={() => {
              this.setState({
                showError: false,
              })
            }}
            message={this.state.error}
          />
        </Box>
      )
    }
  }
}

export default injectIntl(Reset)
