import React, { Fragment, Component } from "react"
import { createGlobalStyle } from "styled-components"
import { Helmet } from "react-helmet"
import { Switch, Route } from "react-router-dom"
import * as Sentry from "@sentry/browser"
import { Theme, withTheme, MuiThemeProvider } from "@material-ui/core/styles"
import theme from "./styles/theme"
import CustomIntlProvider from "./CustomIntlProvider"
import ScrollToTop from "./ScrollToTop"
import Home from "./routes/Home"
import RequestAccount from "./routes/RequestAccount"
import Login from "./routes/Login"
import Reset from "./routes/Reset"
import GuestLogin from "./routes/GuestLogin"
import Send from "./routes/Send"
import NotFound from "./routes/NotFound"

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_SENTRY_ENV,
  debug: process.env.REACT_APP_DEBUG === "true" ? true : false,
})

interface GlobalStyleProps {
  theme: Theme
}

const GlobalStyle = withTheme(createGlobalStyle`
  body {
    margin: 0px;
    padding: 0px;
  }
  hr {
    border-right: none;
    border-bottom: none;
    border-left: none;
  }
  ${(props: GlobalStyleProps) => {
    return `
      body {
        font-family: ${props.theme.typography.fontFamily}
      }
      hr {
        border-top: solid 1px ${props.theme.palette.primary.main};
      }
    `
  }}
`)

class App extends Component {
  render() {
    return (
      <Fragment>
        <Helmet
          title={`Les Industries Poly Inc.`}
          meta={[
            {
              name: "robots",
              content: "noindex, nofollow",
            },
          ]}
        />
        <MuiThemeProvider theme={theme}>
          <CustomIntlProvider>
            <GlobalStyle />
            <ScrollToTop />
            <Switch>
              <Route exact={true} path="/:locale" component={Home} />
              <Route
                exact={true}
                path="/:locale/requestaccount"
                component={RequestAccount}
              />
              <Route exact={true} path="/:locale/login" component={Login} />
              <Route exact={true} path="/:locale/reset" component={Reset} />
              <Route
                exact={true}
                path="/:locale/login/guest"
                component={GuestLogin}
              />
              <Route exact={true} path="/:locale/send" component={Send} />
              <Route component={NotFound} />
            </Switch>
          </CustomIntlProvider>
        </MuiThemeProvider>
      </Fragment>
    )
  }
}

export default App
